import React, {useEffect, useState} from 'react';
import Checkbox from '@/Components/Checkbox';
import InputError from '@/Components/InputError';
import TextInput from '@/Components/TextInput';
import {Head, Link, useForm, usePage} from '@inertiajs/react';
import Footer from "@/Components/MainUI/Footer/Footer";
import logo from "../../../assets/images/logo.png"

import Style from "./Login.module.css"
import Guest from "@/Layouts/GuestLayout";
import {useInternalLink} from "@/Library/Helper";

export default function Login({company}) {
    const { auth } = usePage().props
    const user = auth?.user

    const { data, setData, post, processing, get, errors, reset } = useForm({
        email: '',
        password: '',
        remember: false,
    });

    useEffect(() => {
        return () => {
            reset('password');
        };
    }, []);

    useEffect(() => {
        if (user) {
            get('/dashboard');
        }
    }, [user]);


    useEffect(() => {
        const bodyElement = document.body;
        const divElement = document.querySelector(".antialiased");

        bodyElement.classList.remove("dark-theme","blue-theme");
        divElement?.classList.remove("dark-theme","blue-theme");
        bodyElement.classList.add("light-theme");
        divElement?.classList.add("light-theme");
    }, []);

    const submit = (e) => {
        e.preventDefault();
        post(route('login'));
    };

    const [passwordVisible, setPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    return (
        <>
            <Guest>
                <Head title="Log in" />

                <div className="main-content login-panel login-panel-3 light-theme">
                    <div className="container" style={{display:"block"}}>
                        <div className="d-flex justify-content-end">
                            <div className="login-body">

                                <div className="top d-flex justify-content-between align-items-center">
                                    <div className="logo justify-content-between">
                                        <img width={45} src={company?.logo?useInternalLink(company?.logo):logo} alt="Logo" />
                                        <span className={Style.loginText}>Advance ISP Billing</span>
                                    </div>
                                    <a href="/">
                                        <i className="fa-duotone fa-house-chimney"></i>
                                    </a>
                                </div>


                                <div className="bottom">
                                    <h3 className="panel-title">Admin Login</h3>
                                    <form onSubmit={submit}>
                                        <div className="input-group">
                                            <span className="input-group-text">
                                                <i className="fa-regular fa-user"></i>
                                            </span>
                                            <TextInput type="text" id="email" name="email" value={data.email} className="form-control" autoComplete="username" onChange={(e) => setData('email', e.target.value)} placeholder="Username or email address"/>
                                        </div>
                                        <InputError message={errors.email} className="text-warning"/>
                                        <br/>
                                        <div className="input-group">
                                            <span className="input-group-text"><i className="fa-regular fa-lock"></i></span>
                                            <TextInput
                                                id="password"
                                                type={passwordVisible ? 'text' : 'password'}
                                                name="password"
                                                value={data.password}
                                                className="form-control rounded-end"
                                                autoComplete="current-password"
                                                placeholder="Password"
                                                onChange={(e) => setData('password', e.target.value)}
                                            />

                                            <a
                                                role="button"
                                                className="password-show"
                                                onClick={(e)=>togglePasswordVisibility()}
                                            >
                                                <i className="fa-duotone fa-eye" ></i>
                                            </a>
                                        </div>
                                        <InputError message={errors.password} className="text-warning"/>

                                        <div className="d-flex justify-content-between mt-2">
                                            <div className="form-check">
                                                <Checkbox
                                                    name="remember"
                                                    checked={data.remember}
                                                    onChange={(e) => setData('remember', e.target.checked)}
                                                />
                                                <span className="ms-2 text-sm text-gray-600">Remember me</span>
                                            </div>
                                            <Link href="#" className="text-dark fs-14">Forgot Password?</Link>
                                        </div>
                                        <button className="btn btn-primary w-100 mt-4" disabled={processing}>
                                            Log in
                                        </button>

                                    </form>
                                    <div className="other-option">
                                        <p>Or continue with</p>
                                        <div className="social-box d-flex justify-content-center gap-20">
                                            <Link href="#"><i className="fa-brands fa-facebook-f"></i></Link>
                                            <Link href="#"><i className="fa-brands fa-google"></i></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Footer/>
                </div>
            </Guest>
        </>
    );
}
